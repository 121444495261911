var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"programs"},[_c('div',{staticClass:"programs__filters"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}})],1),_c('div',{staticClass:"programs__table"},[_c('UiTable',{attrs:{"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"527px","show-extra":""},on:{"sort":_vm.applySort},scopedSlots:_vm._u([{key:"link",fn:function(ref){
var row = ref.row;
return [_vm._v(" r="+_vm._s(row.name)+" ")]}},{key:"user",fn:function(ref){
var row = ref.row;
return [_c('User',{attrs:{"id":row.userId,"username":row.userName}})]}},{key:"tag",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex"},_vm._l((_vm.getUserTags(row.userTags)),function(tag){return _c('Tag',{key:'tag-row-' + tag.id,attrs:{"color":tag.color,"class-name":"mr-1"}},[_vm._v(" "+_vm._s(tag.tagName)+" ")])}),1)]}},{key:"dateCreated",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$utils.getDate(row.dateCreated))+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.$utils.getTime(row.dateCreated))+" ")])]}},{key:"referrals",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.referralCount)+" ")]}},{key:"programIncome",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.cumulativeReferralIncomeSum || 0)+" "+_vm._s(_vm.additionalData.cumulativeCurrencyTitle)+" ")]}},{key:"percentage",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex flex-nowrap"},_vm._l((_vm.percentageList),function(item){return _c('div',{key:("percentage-" + (row.id) + "-" + (item.id)),staticClass:"programs__percentage"},[_c('div',[_vm._v(_vm._s(row[item.id] * 100)+"%")]),_c('div',{staticClass:"text--disabled"},[_vm._v(" "+_vm._s(_vm.$t(item.caption))+" ")])])}),0)]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',[(row.active)?_c('span',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.$t('Active'))+" ")]):_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.$t('Disabled'))+" ")])]),_c('div',[(row.fix)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning"}},'v-icon',attrs,false),on),[_vm._v(" mdi-star-circle ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Fixed settings'))+" ")])]):_vm._e()],1)])]}},{key:"extra",fn:function(ref){
var row = ref.row;
return [_c('Extra',{attrs:{"data":row,"currency-title":_vm.additionalData.cumulativeCurrencyTitle},on:{"block":function($event){return _vm.blockProgram(row.id)},"unblock":function($event){return _vm.unblockProgram(row.id)},"update":function($event){return _vm.updateProgram(row.id, $event)},"add":function($event){return _vm.addUserToReferral(row.name, $event)}}})]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('ContentPagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }