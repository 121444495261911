//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Filters from './ReferralProgramProgramsFilters.vue';
import Extra from './ReferralProgramProgramsExtra.vue';
import User from '~/components/User.vue';
import Tag from '~/components/tags/Tag.vue';

import tableConfig from './tableConfig';

export default {
  components: {
    Filters,
    Extra,
    User,
    Tag,
  },

  data() {
    return {
      tableConfig,
      selection: [],
      loader: false,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),
    ...mapGetters('users', ['getUserTagsByIds']),

    ...mapState('referralProgramPrograms', ['page', 'pages', 'data', 'sort', 'additionalData']),

    percentageList: () => ([
      { id: 'buyFee', caption: 'Buy' },
      { id: 'sellFee', caption: 'Sell' },
      { id: 'paraminingFee', caption: 'Staking' },
      { id: 'p2PFee', caption: 'P2P' },
    ]),
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
    ]),
    ...mapActions('referralProgramPrograms', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      updateReferralProgramAction: 'updateReferralProgram',
      addUserToReferralAction: 'addUserToReferral',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    getUserTags(userTags) {
      if (userTags && userTags.length) {
        const idList = userTags.map((e) => e.id);
        return this.getUserTagsByIds(idList);
      }
      return [];
    },

    async blockProgram(referralProgramId) {
      this.setGeneralProgress(true);
      await this.updateReferralProgramAction({
        referralProgramId,
        active: false,
      });
      await this.loadDataAction();
      this.setGeneralProgress(false);
      this.setSuccessNotification('Referral program blocked');
    },

    async unblockProgram(referralProgramId) {
      this.setGeneralProgress(true);
      await this.updateReferralProgramAction({
        referralProgramId,
        active: true,
      });
      await this.loadDataAction();
      this.setGeneralProgress(false);
      this.setSuccessNotification('Referral program unblocked');
    },

    async updateProgram(referralProgramId, data) {
      this.setGeneralProgress(true);
      try {
        await this.updateReferralProgramAction({
          referralProgramId,
          ...data,
        });
        await this.loadDataAction();
        this.setSuccessNotification('Referral program updated');
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.setGeneralProgress(false);
    },

    async addUserToReferral(referral, userId) {
      this.setGeneralProgress(true);
      try {
        await this.addUserToReferralAction({ referral, userId });
        await this.loadDataAction();
        this.setSuccessNotification(this.$t('User was added to the referral program $referral', { referral }));
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.setGeneralProgress(false);
    },
  },
};
