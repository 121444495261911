//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      level: 0,
      model: {
        limit: '',
        buyFee: '',
        sellFee: '',
        p2PFee: '',
        paraminingFee: '',
      },
    };
  },

  computed: {
    ...mapState('referralProgramSettings', ['options']),

    levelList() {
      return [
        { id: 0, caption: '1 level' },
      ];
    },
  },

  watch: {
    options(data) {
      const {
        limit,
        buyFee,
        sellFee,
        p2PFee,
        paraminingFee,
      } = data;

      this.model.limit = limit;
      this.model.buyFee = buyFee;
      this.model.sellFee = sellFee;
      this.model.p2PFee = p2PFee;
      this.model.paraminingFee = paraminingFee;
    },
  },

  methods: {
    ...mapActions('common', [
      'confirmAction',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
    ]),

    ...mapActions('referralProgramSettings', {
      loadReferralProgramSettings: 'loadData',
      updateReferralOptionsAction: 'updateReferralOptions',
    }),

    update() {
      this.confirmAction({
        title: 'Are you sure you want to change referral options?',
        callback: async () => {
          this.setGeneralProgress(true);
          const { level, model } = this;
          const data = { level, ...model };
          try {
            await this.updateReferralOptionsAction(data);
            await this.loadReferralProgramSettings({});
            this.setSuccessNotification('Referral options updated');
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
        },
      });
    },
  },
};
