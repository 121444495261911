var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"owners"},[_c('div',{staticClass:"owners__filters"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}})],1),_c('div',{staticClass:"owners__table"},[_c('UiTable',{attrs:{"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"to-set":function (e) { return e.userId; },"fixed-header":"","height":"527px","show-extra":""},on:{"sort":_vm.applySort},scopedSlots:_vm._u([{key:"user",fn:function(ref){
var row = ref.row;
return [_c('User',{attrs:{"id":row.userId,"username":row.userName}})]}},{key:"tags",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex"},_vm._l((_vm.getUserTags(row.userTags)),function(tag){return _c('Tag',{key:'tag-row-' + tag.id,attrs:{"color":tag.color,"class-name":"mr-1"}},[_vm._v(" "+_vm._s(tag.tagName)+" ")])}),1)]}},{key:"incomeSum",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.cumulativeReferralIncomeSum)+" "+_vm._s(_vm.additionalData.cumulativeCurrencyTitle)+" ")]}},{key:"balance",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.cumulativeReferralBalance || 0)+" "+_vm._s(_vm.additionalData.cumulativeCurrencyTitle)+" ")]}},{key:"dateCreated",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$utils.getDate(row.dateCreated))+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.$utils.getTime(row.dateCreated))+" ")])]}},{key:"referralCount",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.referralCount)+" ")]}},{key:"extra",fn:function(ref){
var row = ref.row;
return [_c('Extra',{attrs:{"data":row}})]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('ContentPagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }