//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import User from '~/components/User.vue';
import UserSelect from '~/components/UserSelect.vue';

export default {

  components: { User, UserSelect },
  props: {
    data: { type: Object, default: () => ({}) },
    currencyTitle: { type: String, default: '' },
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      model: {},
      addUser: {
        userId: '',
      },
      referralsList: {
        modal: false,
        users: [],
      },
    };
  },

  computed: {
    id() {
      const { id } = this.data;
      return id;
    },

    fixItems: () => ([
      { id: false, caption: 'No' },
      { id: true, caption: 'Yes' },
    ]),

    incomesList: () => [
      {
        key: 'cumulativeTradeIncomes',
        caption: 'Trade',
      },
      {
        key: 'cumulativeParaminingIncomes',
        caption: 'Staking',
      },
      {
        key: 'cumulativeP2PIncomes',
        caption: 'P2P',
      },
    ],
  },

  created() {
    this.setModel();
  },

  methods: {
    ...mapActions('common', [
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
    ]),
    ...mapActions('users', ['getUserIdByUserName']),
    ...mapActions('referralProgramPrograms', ['getReferralsByParams']),

    setModel() {
      const {
        buyFee,
        sellFee,
        p2PFee,
        paraminingFee,
        fix,
      } = this.data;
      this.model = {
        buyFee,
        sellFee,
        p2PFee,
        paraminingFee,
        fix,
      };
    },

    block() {
      this.$emit('block');
    },

    unblock() {
      this.$emit('unblock');
    },

    update() {
      const { model } = this;
      this.$emit('update', model);
    },

    async addUserToReferral() {
      const { userId } = this.addUser;
      this.$emit('add', userId);
      // const { username } = this.addUser;
      // this.addUser.loading = true;
      // this.setGeneralProgress(true);
      // const id = await this.getUserIdByUserName(username);
      // if (id) {
      //   this.$emit('add', id);
      //   this.addUser.username = '';
      // } else {
      //   this.setErrorNotification('User with this username does not exist');
      // }
      // this.addUser.loading = false;
      // this.setGeneralProgress(false);
    },

    setUserId(value) {
      this.addUser.userId = value;
    },

    async showCurrentLevelReferrals(currentLevel) {
      const { id } = this.data;

      this.setGeneralProgress(true);
      const { items } = await this.getReferralsByParams({
        referralProgramIdList: [id],
        levelList: [currentLevel],
        limit: 1000,
      });
      this.referralsList.users = items;
      this.referralsList.modal = true;
      this.setGeneralProgress(false);
    },
  },
};
