export default [
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'User',
    },
    cell: {
      type: 'slot',
      name: 'user',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Tags',
    },
    cell: {
      type: 'slot',
      name: 'tags',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Income sum',
      sort: 'cumulativeReferralIncomeSum',
    },
    cell: {
      type: 'slot',
      name: 'incomeSum',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Balance',
      sort: 'cumulativeReferralBalance',
    },
    cell: {
      type: 'slot',
      name: 'balance',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Date created',
      sort: 'dateCreated',
    },
    cell: {
      type: 'slot',
      name: 'dateCreated',
    },
  },
  {
    width: 25,
    header: {
      type: 'text',
      caption: 'Referral count',
      sort: 'referralCount',
    },
    cell: {
      type: 'slot',
      name: 'referralCount',
    },
  },
];
