//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import ReferralProgramPrograms from '~/views/ReferralProgram/ReferralProgramPrograms/ReferralProgramPrograms.vue';
import ReferralProgramSettings from '~/views/ReferralProgram/ReferralProgramSettings/ReferralProgramSettings.vue';
import ReferralProgramOwners from '~/views/ReferralProgram/ReferralProgramOwners/ReferralProgramOwners.vue';

export default {
  components: {
    ReferralProgramPrograms,
    ReferralProgramSettings,
    ReferralProgramOwners,
  },

  async mounted() {
    this.setGeneralLoader(true);
    await Promise.allSettled([
      // this.loadReferralProgramPrograms(),
      // this.loadReferralProgramOwners(),
      this.loadReferralProgramSettings(),
    ]);
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader']),
    ...mapActions('referralProgramPrograms', {
      loadReferralProgramPrograms: 'loadData',
    }),
    ...mapActions('referralProgramOwners', {
      loadReferralProgramOwners: 'loadData',
    }),
    ...mapActions('referralProgramSettings', {
      loadReferralProgramSettings: 'loadData',
    }),
  },
};
