//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Filters from './ReferralProgramOwnersFilters.vue';
import Extra from './ReferralProgramOwnersExtra.vue';
import User from '~/components/User.vue';
import Tag from '~/components/tags/Tag.vue';

import tableConfig from './tableConfig';

export default {
  components: {
    Filters,
    User,
    Tag,
    Extra,
  },

  data() {
    return {
      tableConfig,
      selection: [],
      loader: false,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),
    ...mapGetters('users', ['getUserTagsByIds']),

    ...mapState('referralProgramOwners', ['page', 'pages', 'data', 'sort', 'additionalData']),
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader', 'setSuccessNotification', 'setGeneralProgress']),
    ...mapActions('referralProgramOwners', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    getUserTags(userTags) {
      if (userTags && userTags.length) {
        const idList = userTags.map((e) => e.id);
        return this.getUserTagsByIds(idList);
      }
      return [];
    },
  },
};
