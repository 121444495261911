export default [
  {
    width: 7,
    header: {
      type: 'text',
      caption: 'Link',
    },
    cell: {
      type: 'slot',
      name: 'link',
    },
  },
  {
    width: 16,
    header: {
      type: 'text',
      caption: 'User',
    },
    cell: {
      type: 'slot',
      name: 'user',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Tag',
    },
    cell: {
      type: 'slot',
      name: 'tag',
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Date created',
      sort: 'dateCreated',
    },
    cell: {
      type: 'slot',
      name: 'dateCreated',
    },
  },
  {
    width: 7,
    header: {
      type: 'text',
      caption: 'Referrals',
      sort: 'referralCount',
    },
    cell: {
      type: 'slot',
      name: 'referrals',
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Program income',
    },
    cell: {
      type: 'slot',
      name: 'programIncome',
    },
  },
  {
    width: 22,
    header: {
      type: 'text',
      caption: 'Percentage',
    },
    cell: {
      type: 'slot',
      name: 'percentage',
    },
  },
  {
    width: 9,
    header: {
      type: 'text',
      caption: 'Status',
    },
    cell: {
      type: 'slot',
      name: 'status',
    },
  },
];
