//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import User from '~/components/User.vue';

export default {

  components: {
    User,
  },
  props: {
    data: { type: Object, required: true },
  },

  data() {
    return {
      loader: false,
      referralsList: {
        modal: false,
        users: [],
      },
    };
  },

  computed: {
    programList() {
      const { items } = this.data.innerData;
      return items;
    },

    cumulativeCurrencyTitle() {
      const { cumulativeCurrencyTitle } = this.data.innerData;
      return cumulativeCurrencyTitle;
    },
  },

  async created() {
    const { userId } = this.data;
    this.loader = true;
    this.setGeneralProgress(true);
    await this.loadInnerDataAction(userId);
    this.loader = false;
    this.setGeneralProgress(false);
  },

  methods: {
    ...mapActions('common', ['setGeneralProgress']),
    ...mapActions('referralProgramPrograms', ['getReferralsByParams']),
    ...mapActions('referralProgramOwners', {
      loadInnerDataAction: 'loadInnerData',
    }),

    async showCurrentLevelReferrals(id) {
      this.setGeneralProgress(true);
      const { items } = await this.getReferralsByParams({
        referralProgramIdList: [id],
        // levelList: [currentLevel],
        limit: 1000,
      });
      this.referralsList.users = items;
      this.referralsList.modal = true;
      this.setGeneralProgress(false);
    },
  },
};
